// ==========================================================================
// Mixins
// ==========================================================================
// BEM selectors
@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}


// Generate modifier color classes
@mixin bg-colors($map) {
    @each $theme,
    $color in $map {
        &--#{$theme} {
            background-color: $color;
        }
    }
}

// Generating :before or :after pseudo-element shapes
@mixin p-el( $el,
$el-w: null,
$el-h: null) {
    @if $el=="before" or $el=="after" {
        &:#{$el} {
            @extend %pseudos;
            width: $el-w;
            height: $el-h;
            @content;
        }
    }
    @else {
        @error "`#{$el}` is not a valid pseudo-element.";
    }
}

// Image replacement
@mixin img-replace($img, $w, $h, $disp: block) {
    background-image: url('#{$path--rel}/#{$img}');
    background-repeat: no-repeat;
    width: $w;
    height: $h;
    display: $disp;
}

// Adjacent sibling margins
@mixin doubly($margin: 1em) {
    &+& {
        margin-left: $margin;
        @content;
    }
}

// Grid Columns
@mixin g-columns {
    @for $i from 1 through $g-col-count {
        $context: g-context($g-col-width, $g-col-count, $g-gutter-width) !global;
        $target: ($g-col-width * $i) + ($g-gutter-width * ($i - 1)); // Generate column modifier classes
        &--#{$i} {
            width: percentage($target / $context);
        }
    }
}

// ==========================================================================
// Helpers
// ==========================================================================
.z-index {
  position: relative;

  @include m(1) {
    z-index: 1;
  }
  @include m(2) {
    z-index: 2;
  }
  @include m(3) {
    z-index: 3;
  }
  @include m(4) {
    z-index: 4;
  }
  @include m(5) {
    z-index: 5;
  }
}

// html,
body {
    margin: 0;
    overflow-x: hidden;
    width: 100%;
    background-color: $gray-100;
}
:focus {
    outline: -webkit-focus-ring-color auto 0;
}

h1,h2,h3,h4,h5,h6 {
  color: $gray-700;
}
